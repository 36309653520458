import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { NormalizedComingSoon } from '~utils/normalizers/normalizeComingSoon';

import MoviesCarousel from '../MoviesCarousel';
import OnPresaleMovie from '../OnPresaleMovie';
import PresaleMovie from '../PresaleMovie';
import s from './ComingSoon.module.css';

interface Props {
  data: NormalizedComingSoon[];
}

const ComingSoon: FC<Props> = ({ data }) => {
  const { query } = useRouter();
  const CinemaId = query?.c || query?.CinemaId;
  const presale: NormalizedComingSoon[] = [];
  const onPresale: NormalizedComingSoon[] = [];
  const comingSoon: NormalizedComingSoon[] = [];
  data?.forEach((movie) => {
    const currentCinema = Boolean(
      movie?.CinemaMovies?.filter((item) => `${item.CinemaId}` === CinemaId).length,
    );
    if (!CinemaId || currentCinema) {
      const isPresale = Boolean(movie?.CinemaMovies?.filter?.((item) => item.enablePresale).length);
      if (isPresale) {
        const activePresale = !!movie?.CinemaMovies?.filter?.((item) => {
          return (
            dayjs().isAfter(dayjs(item.presaleStartAt).startOf('day')) &&
            dayjs().isBefore(dayjs(item.premiereAt).endOf('day'))
          );
        })?.length;
        if (activePresale) {
          onPresale.push(movie);
        } else {
          presale.push(movie);
        }
      } else {
        comingSoon.push(movie);
      }
    }
  });
  return (
    <div className={s.root}>
      {!![...onPresale, ...presale]?.length && (
        <div className="space-y-24">
          {!!onPresale.length && (
            <div className={s.presaleContainer}>
              <h2 className={s.title}>Compra en preventa</h2>
              <div className="space-y-24">
                {onPresale?.map((item) => <OnPresaleMovie data={item} key={item.id} />)}
              </div>
            </div>
          )}
          {!!presale.length && (
            <div className={s.presaleContainer}>
              <h2 className={s.title}>Próximamente en preventa</h2>
              <div className="space-y-24">
                {presale?.map((item) => <PresaleMovie data={item} key={item.id} />)}
              </div>
            </div>
          )}
        </div>
      )}
      {!!comingSoon?.length && (
        <div className={s.comingSoon}>
          <h2 className="container h2 text-center">Próximos estrenos</h2>
          <MoviesCarousel movies={comingSoon} />
        </div>
      )}
    </div>
  );
};

export default ComingSoon;
