import cn from 'classnames';
import dayjs from 'dayjs';
import TZ from 'dayjs/plugin/timezone';
import UTC from 'dayjs/plugin/utc';
import { FC } from 'react';
import { preload } from 'swr';

import { DesktopOutlined, StarFilled } from '@ant-design/icons';

import { getFromAPI } from '~utils/api/common';
import go from '~utils/go';

import { Show } from '~types';

import s from './Schedules.module.css';

dayjs.extend(UTC);
dayjs.extend(TZ);

interface Props {
  shows: Show[];
  movieSlug: string;
  movieTitle?: string;
  theme?: 'light' | 'dark' | 'default';
  timezone?: string;
  disabled?: boolean;
  title?: string;
}

const Schedules: FC<Props> = ({
  shows,
  movieSlug,
  theme = 'light',
  timezone,
  disabled,
  movieTitle,
  title,
}) => {
  const normalRoom: Record<string, Show[]> = {};
  const premierRoom: Record<string, Show[]> = {};
  const comfortRoom: Record<string, Show[]> = {};
  shows?.forEach((show) => {
    const projection = `${show.Projection.lang}/${show.Projection.format}`;
    if (show.roomType.comfort) {
      if (comfortRoom[projection]) {
        comfortRoom[projection].push(show);
      } else {
        comfortRoom[projection] = [show];
      }
    } else if (show.roomType.premiere) {
      if (premierRoom[projection]) {
        premierRoom[projection].push(show);
      } else {
        premierRoom[projection] = [show];
      }
    } else {
      if (normalRoom[projection]) {
        normalRoom[projection].push(show);
      } else {
        normalRoom[projection] = [show];
      }
    }
  });

  const renderShows = (items: Show[]) => {
    return (
      <div className={s.schedules}>
        {items
          .filter((item) =>
            dayjs(item.schedule).tz(timezone).isAfter(dayjs().tz(timezone).subtract(20, 'minutes')),
          )
          .map((show) => (
            <a
              data-testid={movieTitle}
              href={`/comprar-boletos/${movieSlug}/${show.sessionId}?step=inicio`}
              key={show.id}
              className={cn(s.show, disabled ? s.disabled : null)}
              title={show.screenName}
              onMouseEnter={() => {
                if (!disabled) {
                  preload(`/shows/seats/${show.sessionId}`, getFromAPI);
                }
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (!disabled) {
                  go(`/comprar-boletos/${movieSlug}/${show.sessionId}?step=inicio`);
                }
              }}
            >
              <span>
                <time
                  dateTime={dayjs(show.schedule)
                    .tz(timezone || 'America/Mazatlan')
                    .format('YYYY-MM-DDTHH:mm:ss')}
                >
                  {dayjs(show.schedule)
                    .tz(timezone || 'America/Mazatlan')
                    .format('hh:mm' + ' a')}
                </time>
              </span>
              {show.roomType.mega && <DesktopOutlined />}
            </a>
          ))}
      </div>
    );
  };

  return (
    <div className={cn(s.root, s[theme], disabled ? 'pointer-events-none' : null)}>
      <span className={s.title}>{title || 'Horarios'}</span>
      {!!Object.keys(normalRoom).length && (
        <div className={s.normalRooms}>
          {Object.keys(normalRoom).map((projection) => (
            <div key={projection} className={s.projection}>
              <span className={s.projectionName}>{projection}</span>
              {renderShows(normalRoom[projection])}
            </div>
          ))}
        </div>
      )}
      {!!Object.keys(premierRoom).length && (
        <div className={s.premierRooms}>
          <span className={s.title}>
            <StarFilled /> <span>Salas Premier</span>
          </span>
          {Object.keys(premierRoom).map((projection) => (
            <div key={projection} className={s.projection}>
              <span className={s.projectionName}>{projection}</span>
              {renderShows(premierRoom[projection])}
            </div>
          ))}
        </div>
      )}
      {!!Object.keys(comfortRoom).length && (
        <div className={s.comfortRooms}>
          <span className={s.title}>
            <span>Salas confort</span>
          </span>
          {Object.keys(comfortRoom).map((projection) => (
            <div key={projection} className={s.projection}>
              <span className={s.projectionName}>{projection}</span>
              {renderShows(comfortRoom[projection])}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Schedules;
